class skbxPageController {
  constructor($window, $scope, $rootScope, $location, $http) {
    var skbxPageWrapper = document.querySelector(".skbx-page-wrapper");
    var stopWheel = true;
    $rootScope.BASE_ANIMATION = "top-bottom-anim";

    setTimeout(function () {
      $rootScope.$apply(function() {
        $rootScope.BASE_ANIMATION = "default-anim";
        $rootScope.STATIC_CONTACT_CLASS = "black";
      });
      stopWheel = false;
    }, 900);


    $window.onwheel = function(event) {
      if (stopWheel) {
        return false;
      }
      if ($location.path() == "/skbx") {
        if (window.pageYOffset < 50 && event.wheelDelta > 0) {
          stopWheel = true;
          $rootScope.$apply(function() {
            $rootScope.BASE_ANIMATION = "bottom-top-anim";
            $rootScope.DEFAULT_PAGE();
          });
          setTimeout(function () {
            $rootScope.$apply(function() {
              $rootScope.BASE_ANIMATION = "default-anim";
              stopWheel = false;
            });
          }, 1400);
        }
        else if (skbxPageWrapper.clientHeight - window.pageYOffset - window.innerHeight < 1 && event.wheelDelta < 0) {
          stopWheel = true;
          skbxPageWrapper.classList.add('close');
          $rootScope.$apply(function() {
            $rootScope.STATIC_CONTACT_CLASS = "white";
          });
          setTimeout(function () {
            $rootScope.$apply(function() {
              $rootScope.BASE_ANIMATION = "top-bottom-anim";
              window.scrollTo(0, 0);
              $rootScope.DEFAULT_PAGE();
            });
          }, 1000);
          setTimeout(function () {
            $rootScope.$apply(function() {
              $rootScope.BASE_ANIMATION = "default-anim";
              stopWheel = false;
            });
          }, 2200);
        }
      }
    }

  };
};

skbxPageController.$inject = ['$window', '$scope', '$rootScope', '$location', '$http'];

export {skbxPageController };
