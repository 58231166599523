class appController {
  constructor($rootScope, $location) {
  	
  };
};

appController.$inject = ['$rootScope', '$location'];

export { appController };







