class newPageController {
  constructor() {
  };
};

newPageController.$inject = [];

export {newPageController };







