import angular from 'angular';

import {skbxPageComponent} from './skbxPage.component';

export var skbxPage = angular.module('skbxPage', [])
.config(function ($stateProvider, $urlRouterProvider) {
  $urlRouterProvider.otherwise('/');
  $stateProvider.state('skbxPage', {
    url: '/skbx',
    template: '<skbx-page></skbx-page>',
    resolve: {
      delay: function ($q, $timeout) {
        var delay = $q.defer();
        $timeout(delay.resolve, 50);
        return delay.promise;
      }
    }
  });
})
.component('skbxPage',skbxPageComponent);
