import angular from 'angular';

import {contactPageComponent} from './contactPage.component';

export var contactPage = angular.module('contactPage', [])
.config(function ($stateProvider, $urlRouterProvider) {
  $urlRouterProvider.otherwise('/');
  $stateProvider.state('contactPage', {
    url: '/contact',
    template: '<contact-page></contact-page>',
    resolve: {
      delay: function ($q, $timeout) {
        var delay = $q.defer();
        $timeout(delay.resolve, 50);
        return delay.promise;
      }
    }
  });
})
.component('contactPage',contactPageComponent);
