// Imports
import ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ from "../../node_modules/html-loader/dist/runtime/getUrl.js";
var ___HTML_LOADER_IMPORT_0___ = new URL("./../assets/img/logo_before.png", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("./../assets/svg/logo_menu.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("./../assets/svg/facebook_hover.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_3___ = new URL("./../assets/svg/facebook.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_4___ = new URL("./../assets/svg/linkedin_hover.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_5___ = new URL("./../assets/svg/linkedin.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_6___ = new URL("./../assets/svg/instagram_hover.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_7___ = new URL("./../assets/svg/instagram.svg", import.meta.url);
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var ___HTML_LOADER_REPLACEMENT_3___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_3___);
var ___HTML_LOADER_REPLACEMENT_4___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_4___);
var ___HTML_LOADER_REPLACEMENT_5___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_5___);
var ___HTML_LOADER_REPLACEMENT_6___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_6___);
var ___HTML_LOADER_REPLACEMENT_7___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_7___);
var code = "<div class=\"static-contacts-wrapper\"> <div class=\"static-contacts-menu\" ng-click=\"$ctrl.menuToggle()\"> <span class=\"menu-txt\" ng-bind-html=\"$ctrl.txt\"></span> <span class=\"menu-rect\"></span> <span class=\"menu-rect\"></span> <span class=\"menu-rect\"></span> </div> <div class=\"static-contacts-page {{$ctrl.className}}\" ng-click=\"$ctrl.menuOpenHide($event)\"> <div class=\"static-contacts-content\"> <div class=\"contacts-logo-wrapper\"> <img class=\"contacts-logo-before\" src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" alt=\"logo\"> <img class=\"contacts-logo\" src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\" alt=\"logo\"> </div> <nav> <ul class=\"nav-site-list\"> <li class=\"nav-site-list_item\"> <a class=\"nav-site-list_link\" href=\"/contact\">Contact</a> </li> </ul> <ul class=\"nav-social-list\"> <li class=\"nav-social-list_item\"> <a href=\"https://www.facebook.com/radacode\"> <img class=\"fb-icon icon__hover\" src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\" alt=\"fb\"> <img class=\"fb-icon icon__default\" src=\"" + ___HTML_LOADER_REPLACEMENT_3___ + "\" alt=\"fb\"> </a> </li> <li class=\"nav-social-list_item\"> <a href=\"https://www.linkedin.com/company-beta/2932006/\"> <img class=\"ln-icon icon__hover\" src=\"" + ___HTML_LOADER_REPLACEMENT_4___ + "\" alt=\"ln\"> <img class=\"ln-icon icon__default\" src=\"" + ___HTML_LOADER_REPLACEMENT_5___ + "\" alt=\"ln\"> </a> </li> <li class=\"nav-social-list_item\"> <a href=\"https://www.instagram.com/radacode/\"> <img class=\"insta-icon icon__hover\" src=\"" + ___HTML_LOADER_REPLACEMENT_6___ + "\" alt=\"insta\"> <img class=\"insta-icon icon__default\" src=\"" + ___HTML_LOADER_REPLACEMENT_7___ + "\" alt=\"insta\"> </a> </li> </ul> </nav> </div> </div> </div>";
// Exports
export default code;