class staticContactsController {
  constructor($rootScope, $http, $location, $timeout) {

    $rootScope.$watch('MENU_TEXT', function () {
      this.txt = $rootScope.MENU_TEXT;
    }.bind(this));

    this.className = "static-contacts-page__close";

    this.menuToggle = function () {
      if ($rootScope.MENU_HOME) {
        $location.path('/');
        $timeout(function () {
          $rootScope.MENU_TEXT = "me<br>nu";
        }.bind(this), 200);
        $rootScope.MENU_HOME = false;
      }
      else if (this.className == "static-contacts-page__close") {
        this.className = "static-contacts-page__open";
        $rootScope.MENU_TEXT = "clo<br>se";
        $rootScope.COMPONENT_VISIBL = "component-hide";
        if ($location.path() == "/skbx") {
          $rootScope.STATIC_CONTACT_CLASS = "white";
        }
      }
      else if (this.className == "static-contacts-page__open") {
        this.className = "static-contacts-page__close";
        $rootScope.MENU_TEXT = "me<br>nu";
        $rootScope.COMPONENT_VISIBL = "component-visible";
        if ($location.path() == "/skbx") {
          $rootScope.STATIC_CONTACT_CLASS = "black";
        }
      }
    };

    this.menuOpenHide = function (event) {
      if (event.target.closest("a.nav-site-list_link")) {
        if (event.target.getAttribute('href') == "/contact") {
          this.className = "static-contacts-page__close";
          $rootScope.COMPONENT_VISIBL = "component-visible";
        }
      }
    };
    
  }  
};

staticContactsController.$inject = ['$rootScope', '$http', '$location', '$timeout'];

export { staticContactsController };