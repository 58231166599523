// Imports
import ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ from "../../../node_modules/html-loader/dist/runtime/getUrl.js";
var ___HTML_LOADER_IMPORT_0___ = new URL("../../assets/svg/constellation_02.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("../../assets/img/logo_before.png", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("../../assets/svg/logo_home.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_3___ = new URL("../../assets/svg/skbx.svg", import.meta.url);
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var ___HTML_LOADER_REPLACEMENT_3___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_3___);
var code = "<div class=\"homepage-wrapper\"> <div class=\"homepage-star-wrapper\"> <img class=\"constellation constellation-product\" src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\"> </div> <div class=\"logo-wrapper\"> <img class=\"logo_img-before\" src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\"> <img class=\"logo_img\" src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\"> </div> <div class=\"homepage-link-wrapper\"> <a class=\"homepage-link homepage-link__skbx\" href=\"/skbx\" ng-click=\"$ctrl.skbxLink()\"> <img class=\"homepage-link_img\" src=\"" + ___HTML_LOADER_REPLACEMENT_3___ + "\"> <span class=\"homepage-link_text homepage-link_text__left\">SKBX</span> </a> </div> <div class=\"homepage-link-wrapper\"> <span class=\"homepage-link__noHover homepage-link__product\"> <span class=\"homepage-link_text homepage-link_text__center\">Products</span> </span> </div> </div>";
// Exports
export default code;