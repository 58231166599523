class homePageController {
  constructor($rootScope, $window) {
  	$rootScope.STATIC_CONTACT_CLASS = "white";
  };
};

homePageController.$inject = ['$rootScope', '$window'];

export { homePageController };







