import angular from 'angular';

import {newPageComponent} from './newPage.component';

export var newPage = angular.module('newPage', [])
.config(function ($stateProvider, $urlRouterProvider) {
  $urlRouterProvider.otherwise('/');
  $stateProvider.state('newPage', {
    url: '/newPage',
    template: '<new-page></new-page>',
    resolve: {
      delay: function ($q, $timeout) {
        var delay = $q.defer();
        $timeout(delay.resolve, 50);
        return delay.promise;
      }
    }
  });
})
.component('newPage',newPageComponent);
