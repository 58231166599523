import { homePage } from './homePage/homePage';
import { newPage } from './newPage/newPage';
import { contactPage } from './contactPage/contactPage';
import { skbxPage } from './skbxPage/skbxPage';


import angular from 'angular';
import ngAnimate from 'angular-animate';

export var appViewPage = angular.module('appViewPage', [
	ngAnimate,
  homePage.name,
  newPage.name,
  contactPage.name,
  skbxPage.name
]);
