
import './assets/styles/global.sass';
import './assets/img/favicon.ico';
import './index.html';

import {staticContactsComponent} from './staticContacts/staticContacts.component';
import {appComponent} from './app/app.component';

import {appViewPage} from './app/app.viewPage';

import angular from 'angular';
import uiRouter from 'angular-ui-router';
import ngSanitize from 'angular-sanitize';

angular.module('app', [uiRouter, ngSanitize, appViewPage.name])
.config(function ($locationProvider) {
	$locationProvider.html5Mode({
		enabled: true,
		requireBase: false
	});
})
.run(function ($rootScope, $location) {
	$rootScope.COMPONENT_VISIBL = "component-visible";
	$rootScope.STATIC_CONTACT_CLASS = "white";
	$rootScope.BASE_ANIMATION = "default-anim";
	$rootScope.MENU_HOME = false;
	$rootScope.MENU_TEXT = "me<br>nu";
	$rootScope.DEFAULT_PAGE = function () {
		$location.path('/');
	};
})
.component('app', appComponent)
.component('staticContacts', staticContactsComponent);