import angular from 'angular';

import {homePageComponent} from './homePage.component';

export var homePage = angular.module('homePage', [])
.config(function ($stateProvider, $urlRouterProvider) {
  $urlRouterProvider.otherwise('/');
  $stateProvider.state('homePage', {
    url: '/',
    template: '<home-page></home-page>',
    resolve: {
      delay: function ($q, $timeout) {
        var delay = $q.defer();
        $timeout(delay.resolve, 50);
        return delay.promise;
      }
    }
  });
})
.component('homePage',homePageComponent);
