class contactPageController {
  constructor($rootScope) {
  	$rootScope.MENU_HOME = true;
  	$rootScope.MENU_TEXT = "ho<br>me";
  };
};

contactPageController.$inject = ['$rootScope'];

export {contactPageController };
